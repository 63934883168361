import { Box, Button, CircularProgress, Grid } from "@mui/material";
import "../../../../CommonCss/styles.css";
import GridRecord from "./Components/GridRecord/GridRecord";
import FormField from "../../../../CommonComponents/FormField";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router-dom";
const customTextFieldStyling = {
  "& .MuiInputLabel-root": {
    color: "white",
    fontFamily: "Times",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& fieldset": {
    borderBottomColor: "white",
    borderWidth: 0.1,
  },
};
const ContactDetails = () => {
  const initialValues = useMemo(
    () => ({
      details: "",
      name: "",
      email: "",
      number: "",
    }),
    []
  );
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      style={{ backgroundColor: "black" }}
      sx={{
        paddingLeft: { xs: 7, md: 15 },
        paddingRight: { xs: 7, md: 15 },
        paddingTop: { xs: 7, md: 10 },
      }}
    >
      <Grid items xs={12} sm={4}>
        <h1
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "white",
          }}
        >
          Work with the Experts
        </h1>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h4
            style={{
              fontWeight: 300,
              color: "white",
              fontFamily: "AdorHairline-light",
            }}
          >
            We’re always for your business.
          </h4>
          <h4
            style={{
              fontWeight: 300,
              color: "white",
              fontFamily: "AdorHairline-light",
            }}
          >
            Reach out to our experts and request a meeting and tell us about
            your project, we’ll get back to you with a suitable meeting date or
            a proposal. You can also give us a call or send us an email on the
            contacts below.
          </h4>
          <Box style={{ marginTop: 70 }}>
            <GridRecord
              primaryText="Phone"
              secondaryText="01208887658"
              primaryFontWeight={1000}
              secondaryFontWeight={100}
            />
            <GridRecord
              primaryText="Email"
              secondaryText="info@alfa-egypt.com"
              primaryFontWeight={500}
              secondaryFontWeight={400}
              isEmail
            />
            <GridRecord
              primaryText="Address"
              secondaryText="Industrial district 4. Plot no. 82, 6th of October, Giza, Egypt"
              primaryFontWeight={500}
              secondaryFontWeight={400}
            />
          </Box>
        </div>
      </Grid>
      <Grid items xs={12} sm={4}>
        <h1
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "white",
          }}
        >
          Write A Comment
        </h1>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              setLoading(true);
              setIsError(false);
              const response = await axios.post(
                `${API_URL}/clients/contact-us`,
                values,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              setLoading(false);
              navigate("confirmation");
            } catch (error) {
              console.error("Error submitting form:", error);
              setLoading(false);
              setIsError(true);
            }
          }}
        >
          {({ values }) => (
            <Form>
              <Grid
                direction="column"
                justifyContent="space-evenly"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <FormField
                    type="input"
                    name={`details`}
                    label="Details about your request"
                    fullwidth
                    required
                    color="white"
                    customizedStyling={customTextFieldStyling}
                    customMargin={20}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="input"
                    name={`name`}
                    label="Name"
                    fullwidth
                    required
                    color="white"
                    customizedStyling={customTextFieldStyling}
                    customMargin={20}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="input"
                    name={`number`}
                    label="Phone"
                    fullwidth
                    required
                    color="white"
                    customizedStyling={customTextFieldStyling}
                    customMargin={20}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="input"
                    name={`email`}
                    label="Email"
                    fullwidth
                    required
                    color="white"
                    customizedStyling={customTextFieldStyling}
                    customMargin={20}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                style={{
                  color: "black",
                  textDecoration: "underline",
                  backgroundColor: "#A99E8D",
                  marginTop: 40,
                  borderRadius: 0,
                  padding: 20,
                  width: 200,
                  fontWeight: 500,
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "black" }} />
                ) : (
                  "SUBMIT NOW"
                )}
              </Button>

              {isError && (
                <h4
                  style={{
                    fontFamily: "AdorHairline-Bold",
                    marginTop: 15,
                    color: "white",
                  }}
                >
                  Oops, an error occurred. Please try again. If the issue
                  persists, call us on the specified number.{" "}
                </h4>
              )}
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
export default ContactDetails;
