import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Home from "./pages/Home";
import MetalFabrication from "./pages/MetalFabrication";
import Furnishing from "./pages/Furnishing";
import Electrostatic from "./pages/Electrostatic";
import Collection from "./pages/Collection";
import CategoryProducts from "./pages/Collection/Components/CategoryProducts";
import Contact from "./pages/Contact";
import AdminSignIn from "./pages/Admin";
import AdminLayout from "./pages/Admin/Components/AdminLayout";
import AdminProfile from "./pages/Admin/Components/Profile";
import Employees from "./pages/Admin/Components/Employees";
import Clients from "./pages/Admin/Components/Clients";
import ProjectDetails from "./CommonComponents/ProjectDetails";
import Product from "./pages/Collection/Components/Product";
import Achievments from "./pages/Admin/Components/Achievments/Achievments";
import Projects from "./pages/Admin/Components/Projects/Projects";
import Products from "./pages/Admin/Components/Products/Products";
import ResetPassword from "./pages/Admin/Components/ResetPassword/ResetPassword";
import ResetPasswordForm from "./pages/Admin/Components/ResetPasswordForm/ResetPasswordForm";
import Layout from "./CommonComponents/Layout/Layout";
import Categories from "./pages/Admin/Components/Categories/Categories";
import Subcategories from "./pages/Admin/Components/SubCategories/Subcategories";
import SubCategories from "./pages/Collection/Components/SubCategories";
import BestSellers from "./pages/Admin/Components/FeaturedProducts/FeaturedProducts";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Confirmation from "./pages/Confirmation";

const App = () => {
  return (
    <MuiThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/Metal-Fabrication" element={<MetalFabrication />} />
            <Route path="/Categories" element={<Collection />} />
            <Route
              path="/Categories/:categoryName"
              element={<CategoryProducts />}
            />
            <Route
              path="/Categories/:categoryName/:subCategoryName"
              element={<CategoryProducts />}
            />
            <Route
              path="/Categories/:categoryName/subcategories"
              element={<SubCategories />}
            />

            <Route path="/Collection/:productId" element={<Product />} />
          </Route>
          <Route path="/Furnishing" element={<Furnishing />} />
          <Route path="/Furnishing/:category" element={<Furnishing />} />
          <Route path="/Contact-us" element={<Contact />} />
          <Route path="/Project-details/:id" element={<ProjectDetails />} />
          <Route path="/Electrostatic" element={<Electrostatic />} />
          <Route path="/Privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Contact-us/confirmation" element={<Confirmation />} />
          Admin portal routes
          <Route path="/admin" element={<AdminSignIn />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/reset-password/:token/:userId"
            element={<ResetPassword />}
          />
          <Route path="/send-reset-password/" element={<ResetPasswordForm />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="employees" element={<Employees />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="clients" element={<Clients />} />
            <Route path="achievments" element={<Achievments />} />
            <Route path="projects" element={<Projects />} />
            <Route path="products" element={<Products />} />
            <Route path="categories" element={<Categories />} />
            <Route path="subcategories" element={<Subcategories />} />
            <Route path="bestSellers" element={<BestSellers />} />
          </Route>
        </Routes>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
